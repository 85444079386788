<template>
  <v-form ref="form" v-model="valid">
    <v-card class="mb-5" :loading="loadingCard">
      <v-card-title>Account Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="API Key"
              class="dt-text-field"
              outlined
              :rules="rules.apiKey"
              v-model="form.apiKey"
              :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showKey ? 'text' : 'password'"
              @click:append="showKey = !showKey"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="API URL"
              class="dt-text-field"
              outlined
              :rules="rules.apiUrl"
              v-model="form.apiUrl"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Sender ID"
              class="dt-text-field"
              outlined
              :rules="rules.senderId"
              v-model="form.senderId"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              append-icon="mdi-chevron-down"
              :items="dealers"
              item-text="text"
              item-value="value"
              label="Dealer"
              outlined
              clearable
              clear-icon="mdi-close-circle-outline"
              :rules="rules.dealer"
              v-model="form.dealer"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea
              type="textarea"
              label="Remarks"
              outlined
              v-model="form.remarks"
              rows="3"
              row-height="30"
              auto-grow
            ></v-textarea>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field
              type="datetime-local"
              label="Expiry Date"
              class="dt-text-field"
              outlined
              v-model="form.expiryDate"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12">
            <v-switch label="Status" hide-details v-model="form.status"></v-switch>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6" flat tile>
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            @click="validate(false)"
            :loading="loadingSave"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green lighten-1"
            class="ma-1 white--text"
            @click="validate(true)"
            :loading="loadingSaveAndExit"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn
            large
            exact
            color="warning"
            class="ma-1 white--text"
            :disabled="loadingSave || loadingSaveAndExit"
            :to="{ name: 'infobip-account.index' }"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    details: {
      required: false,
      type: Object | Array,
      default() {
        return {
          api_key: null,
          api_url: null,
          sender_id: null,
          dealer_id: null,
          remarks: null,
          expired_at: null,
          status: false,
        };
      },
    },
  },
  data() {
    return {
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      loadingCard: false,
      form: {
        apiKey: "",
        apiUrl: "",
        senderId: "",
        expiryDate: "",
        dealer: "",
        remarks: "",
        status: false,
      },
      showKey: true,
      valid: true,
      rules: {
        apiKey: [(value) => !!value || "API Key is required"],
        apiUrl: [(value) => !!value || "API Url is required"],
        senderId: [(value) => !!value || "Sender ID is required"],
        dealer: [(value) => !!value || "Dealer is required"],
      },
    };
  },
  watch: {
    details: {
      handler(value) {
        if (value) {
          this.form.apiKey = this.details.api_key;
          this.form.apiUrl = this.details.api_url;
          this.form.senderId = this.details.sender_id;
          this.form.dealer = this.details.dealer_id;
          this.form.remarks = this.details.remarks;
          this.form.expiryDate = this.details.expired_at;
          this.form.status = this.details.status;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      dealers: "dealer/GET_DEALERS",
    }),
  },
  async mounted() {
    this.loadingCard = true;
    await this.getDealers();

    this.loadingCard = false;
  },
  methods: {
    ...mapActions({
      save: "infobipAccount/save",
      getDealers: "dealer/getDealers",
    }),
    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveAccount(isExit);
    },
    async saveAccount(isExit) {
      const id = this.$route.params.id ? this.$route.params.id : null;
      const data = {
        api_key: this.form.apiKey,
        api_url: this.form.apiUrl,
        sender_id: this.form.senderId,
        dealer_id: this.form.dealer,
        remarks: this.form.remarks,
        expired_at: this.form.expiryDate,
        status: this.form.status,
      };
      await this.save({
        id,
        data,
      }).then((data) => {
        this.loadingSaveAndExit = false;
        this.loadingSave = false;
      });

      if (!id) {
        this.$refs.form.reset();
        this.form.status = false;
      }

      if (isExit) {
        this.$router.push({ name: "infobip-account.index" });
      }
    },
  },
};
</script>

<style></style>
